import { useEffect, useMemo, useRef, useState } from 'react';
import type { ModalProps } from 'antd';
import { message, Modal } from 'antd';
import { IShipmentInfo } from 'src/api/types/temporary';
import { Form, FormRef } from 'src/components/Form';
import { schema } from './config';
import { DatePicker, Input, NumberPicker, Switch, Select } from '@formily/antd-v5';
import { Field, onFieldValueChange, onFormMount } from '@formily/core';
import { createShipment, updateShipment } from 'src/api/clients/temporary';
import { omit } from 'lodash-es';
import { useBoolean, useMemoizedFn } from 'ahooks';

export interface IPaperModalProps extends ModalProps {
  data?: IShipmentInfo | null;
  onRefresh?: () => void;
  onCancel?: () => void;
}

export function TransportModal(props: IPaperModalProps) {
  const { data, onRefresh, open, onCancel, ...rest } = props;
  const [formMount, setFormMount] = useState(false);
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const onCacnelHandle = useMemoizedFn(() => {
    const instance = formRef.current?.getFormInstance();
    instance?.reset();
    onCancel?.();
  });
  const onSubmit = async () => {
    const instance = formRef.current?.getFormInstance();
    if (!instance) {
      return;
    }
    const result = await instance.submit<any>();
    const request = data ? updateShipment : createShipment;
    setTrue();
    try {
      await request({
        ...result,
        id: data?.id,
      });
      message.success('操作成功');
      onRefresh?.();
      onCacnelHandle?.();
    } catch (err) {
      console.log(err);
      message.info('网络请求异常，请稍后重试');
    } finally {
      setFalse();
    }
  };

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMount(true);
        });
        onFieldValueChange('quantity', (field) => {
          const value = field.value;
          const unitPrice = field.query('unitPrice').take();
          if (value && (unitPrice as Field)?.value) {
            const total = field.query('productAmount').take();
            (total as Field).setState({ value: value * (unitPrice as Field).value });
          }
        });
        onFieldValueChange('unitPrice', (field) => {
          const value = field.value;
          const quantity = field.query('quantity').take();
          if (value && (quantity as Field)?.value) {
            const total = field.query('productAmount').take();
            (total as Field).setState({ value: value * (quantity as Field).value });
          }
        });
        onFieldValueChange('productAmount', (field) => {
          const value = field.value;
          const freight = field.query('freight').take();
          if (value && (freight as Field)?.value) {
            const total = field.query('totalAmount').take();
            (total as Field).setState({ value: value + (freight as Field).value });
          }
        });
        onFieldValueChange('freight', (field) => {
          const value = field.value;
          const productAmount = field.query('productAmount').take();
          if (value && (productAmount as Field)?.value) {
            const total = field.query('totalAmount').take();
            (total as Field).setState({ value: value + (productAmount as Field).value });
          }
        });
      },
    };
  }, []);

  useEffect(() => {
    if (!formMount) {
      return;
    }
    const instance = formRef.current?.getFormInstance();
    instance?.setValues(
      omit(
        {
          ...data,
          paymentMethod: isNaN(Number(data?.paymentMethod)) ? null : Number(data?.paymentMethod),
        },
        ['id'],
      ),
    );
  }, [data, formMount]);

  return (
    <Modal
      open={open}
      onCancel={onCacnelHandle}
      confirmLoading={visible}
      width={800}
      title={`运输单${data ? '编辑' : '新建'}`}
      onOk={onSubmit}
      {...rest}>
      <Form
        hideAction
        ref={formRef}
        schema={schema}
        formOptions={formOptions}
        components={{ Input, Switch, DatePicker, NumberPicker, Select }}
        grid={{ maxColumns: 2 }}
        layout={{ layout: 'vertical' }}
      />
    </Modal>
  );
}
