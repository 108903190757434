import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import type { IFormSchema } from 'src/components/Form';
import {
  countryOptions,
  orderCompletedMap,
  PaymentMethodEnum,
  paymentMethodMap,
  paymentOptions,
  quanitityFlagMap,
  quanitityFlagOptions,
  shippedMap,
} from './const';

export const searchSchema: QueryTableFilterISchemaProperties = {
  id: {
    type: 'string',
    title: '运输单号',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入运输单号',
    },
  },
  '[startTime, endTime]': {
    type: 'array',
    title: '操作时间',
    'x-component': 'DatePicker.RangePicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: ['开始时间', '结束时间'],
      format: 'YYYY-MM-DD HH:mm:ss',
      showTime: true,
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const columns: QueryTableColumnProps = [
  {
    title: '运输单号',
    key: 'shippingNumber',
    dataIndex: 'shippingNumber',
    width: 150,
  },
  {
    title: '运输凭证',
    key: 'shippingCertificate',
    dataIndex: 'shippingCertificate',
    width: 150,
  },
  {
    title: '时间',
    key: 'time',
    dataIndex: 'time',
    width: 200,
  },
  {
    title: '客户名字',
    key: 'name',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: '国家',
    key: 'country',
    dataIndex: 'country',
    width: 150,
  },
  {
    title: 'whatsApp',
    key: 'whatsApp',
    dataIndex: 'whatsApp',
    width: 150,
  },
  {
    title: '联系方式',
    key: 'contact',
    dataIndex: 'contact',
    width: 200,
  },
  {
    title: '地址',
    key: 'address',
    dataIndex: 'address',
    width: 200,
  },
  {
    title: '产品',
    key: 'product',
    dataIndex: 'product',
    width: 150,
  },
  {
    title: '纯度',
    key: 'purity',
    dataIndex: 'purity',
    width: 150,
  },
  {
    title: '规格',
    key: 'specification',
    dataIndex: 'specification',
    width: 150,
  },
  {
    title: '数量',
    key: 'quantity',
    dataIndex: 'quantity',
    width: 150,
  },
  {
    title: '单价',
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    width: 150,
  },
  {
    title: '数量单位',
    key: 'quantityFlag',
    dataIndex: 'quantityFlag',
    width: 150,
    render: (val: number) => {
      return quanitityFlagMap[val];
    },
  },
  {
    title: '产品金额',
    key: 'productAmount',
    dataIndex: 'productAmount',
    width: 150,
  },
  {
    title: '运费',
    key: 'freight',
    dataIndex: 'freight',
    width: 150,
  },
  {
    title: '总额',
    key: 'totalAmount',
    dataIndex: 'totalAmount',
    width: 150,
  },
  {
    title: '支付方式',
    key: 'paymentMethod',
    dataIndex: 'paymentMethod',
    width: 150,
    render(val: number) {
      return paymentMethodMap[val as PaymentMethodEnum];
    },
  },
  {
    title: '是否发货',
    key: 'isShipped',
    dataIndex: 'isShipped',
    width: 150,
    render(val: number) {
      return shippedMap[val];
    },
  },
  {
    title: '是否已完成订单',
    key: 'isOrderCompleted',
    dataIndex: 'isOrderCompleted',
    width: 150,
    render(val: number) {
      return orderCompletedMap[val];
    },
  },
];

export const schema: IFormSchema = {
  layout: {
    type: 'void',
    'x-component': 'FormGrid',
    'x-component-props': {
      maxColumns: 2,
    },
    properties: {
      shippingNumber: {
        type: 'string',
        title: '运输单号',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入运输单号',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入运输单号' }],
      },
      shippingCertificate: {
        type: 'string',
        title: '运输凭证',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入运输凭证',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入运输凭证' }],
      },
      time: {
        type: 'string',
        title: '时间',
        'x-component': 'DatePicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择时间',
          showTime: true,
          format: 'YYYY-MM-DD HH:mm:ss',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请选择时间' }],
      },
      name: {
        type: 'string',
        title: '客户名字',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入客户名字',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入客户名字' }],
      },
      country: {
        type: 'string',
        title: '国家',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择国家',
          options: countryOptions,
          showSearch: true,
          filterOption: (input: string, option: { label: string; value: string }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请选择国家' }],
      },
      whatsApp: {
        type: 'string',
        title: 'whatsApp',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入whatsApp',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入whatsApp' }],
      },
      contact: {
        type: 'string',
        title: '联系方式',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入联系方式',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入联系方式' }],
      },
      address: {
        type: 'string',
        title: '地址',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入地址',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入地址' }],
      },
      product: {
        type: 'string',
        title: '产品',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入产品',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入产品' }],
      },
      specification: {
        type: 'string',
        title: '规格',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入规格',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入规格' }],
      },
      purity: {
        type: 'string',
        title: '纯度',
        'x-component': 'Input',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入纯度',
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入纯度' }],
      },
      quantity: {
        type: 'number',
        title: '数量',
        'x-component': 'NumberPicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入数量',
          min: 0,
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入数量' }],
      },
      quantityFlag: {
        type: 'number',
        title: '数量单位',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择单位',
          options: quanitityFlagOptions,
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请选择单位' }],
      },
      unitPrice: {
        type: 'number',
        title: '单价',
        'x-component': 'NumberPicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入单价',
          min: 0,
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入单价' }],
      },
      productAmount: {
        type: 'number',
        title: '产品金额',
        'x-component': 'NumberPicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入产品金额',
          min: 0,
        },

        'x-decorator-props': {
          gridSpan: 1,
          tooltip: '单价 * 数量',
        },
        'x-validator': [{ required: true, message: '请输入产品金额' }],
      },
      freight: {
        type: 'number',
        title: '运费',
        'x-component': 'NumberPicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入运费',
          min: 0,
          precision: 0.01,
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入运费' }],
      },
      totalAmount: {
        type: 'number',
        title: '订单总额',
        'x-component': 'NumberPicker',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入总额',
        },
        'x-decorator-props': {
          gridSpan: 1,
          tooltip: '产品的总金额 + 运费',
        },
        'x-validator': [{ required: true, message: '请输入总额' }],
      },
      paymentMethod: {
        type: 'string',
        title: '支付方式',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请输入支付方式',
          options: paymentOptions,
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请输入支付方式' }],
      },
      isShipped: {
        type: 'number',
        title: '是否发货',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择是否发货',
          options: [
            {
              label: '未发货',
              value: 0,
            },
            {
              label: '已发货',
              value: 1,
            },
          ],
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请选择是否发货' }],
      },
      isOrderCompleted: {
        type: 'number',
        title: '是否已完成订单',
        'x-component': 'Select',
        'x-decorator': 'FormItem',
        'x-component-props': {
          placeholder: '请选择是否已完成订单',
          options: [
            {
              label: '待完成',
              value: 0,
            },
            {
              label: '已完成',
              value: 1,
            },
          ],
        },
        'x-decorator-props': {
          gridSpan: 1,
        },
        'x-validator': [{ required: true, message: '请选择是否已完成订单' }],
      },
    },
  },
};
