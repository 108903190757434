import fileSaver from 'file-saver';

const getFileNameFromDisposition = (disposition: string) => {
  // disposition 字符串是后端拼接的,可能存在每个人返回的字符串内容不一样
  const result = disposition.match(/attachment;\s*filename="(\S+)"/);
  if (result) {
    return result[1];
  }
  const resultUnicode = disposition.match(/attachment;\s*filename\*=(utf-8'')*(\S+)/);
  return resultUnicode && resultUnicode[1] && decodeURIComponent(resultUnicode[2]);
};

export const handleResponseBlob = async (
  response: any, // AxiosResponse<Blob>,
  filename = '异常数据.xlsx',
) => {
  const { data, headers } = response;
  if (data.type.includes('application/json')) {
    const res = await data.text();
    return JSON.parse(res);
  }
  const disposition = headers['content-disposition'];
  const errorFileName = (disposition && getFileNameFromDisposition(disposition)) || filename;
  fileSaver.saveAs(data, errorFileName);
  return undefined;
};

export function downloadFile(data: File, filename: string, mime?: string) {
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}
