import request from 'src/api/http';
import type { IQueryShipment, IShipmentInfo } from '../types/temporary';

export function createShipment(data: IShipmentInfo) {
  return request.post('/shipments/addShipment', { data });
}

export function getShipmentList(data: IQueryShipment) {
  return request.post('/shipments/getShipments', {
    params: data,
  });
}

export function deleteShipment(id: number) {
  return request.post(`/shipments/deleteShipDetail/${id}`);
}

export function updateShipment(data: IShipmentInfo & { id: number }) {
  const { id, ...rest } = data;
  return request.post(`/shipments/updateShipment/${id}`, { data: rest });
}

export function exportShipments(data: Partial<IQueryShipment>) {
  return request.post('/shipments/exportShipments', { data, responseType: 'blob' });
}
