import { useBoolean, useMemoizedFn } from 'ahooks';
import { Card, Button, Modal, message } from 'antd';
import { AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai';
import { deleteShipment, getShipmentList, exportShipments } from 'src/api/clients/temporary';
import { QueryTable, QueryTableColumnProps, QueryTableFilterRef } from 'src/components/QueryTable';
import { columns, searchSchema } from './config';
import { TransportModal } from './transport-modal';
import { Input, DatePicker } from '@formily/antd-v5';
import { useMemo, useRef, useState } from 'react';
import { IQueryShipment, IShipmentInfo } from 'src/api/types/temporary';
import dayjs from 'dayjs';
import { handleResponseBlob } from 'src/utils/download';

const TransportTableName = 'TransportTableName';

export default function TransportManage() {
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [currentData, setCurrentData] = useState<IShipmentInfo | null>(null);
  const formRef = useRef<QueryTableFilterRef | null>(null);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    size: 10,
  });

  const getTestPapers = useMemoizedFn(async ({ limit, offset, id, startTime, endTime }) => {
    const page = offset / limit + 1;
    setPageInfo({
      page,
      size: limit,
    });
    const result = await getShipmentList({
      page,
      size: limit,
      id: id,
      startTime,
      endTime,
    });
    return {
      data: result?.data,
      total: result?.totalElements,
    };
  });

  const onDownload = useMemoizedFn(async () => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IQueryShipment>();
    const { id, startTime, endTime } = values || {};
    try {
      const data = await exportShipments({
        id,
        startTime,
        endTime,
        ...pageInfo,
      });
      handleResponseBlob(data, `运输单明细_${dayjs().format('YYYY-MM-DD')}.xlsx`);
      message.success('下载成功');
    } catch (error) {
      message.error(`下载失败，原因: ${(error as Error)?.message}`);
    }
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      TransportTableName,
    );
  });

  const onDeleteDeptByIds = useMemoizedFn(async (id: number) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定运输单吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteShipment(id);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 100,
        renderType: 'Operation',
        renderProps: (col: IShipmentInfo) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentData(col);
                  setTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  if (!col.id) {
                    message.error('缺少必要ID，无法删除');
                    return;
                  }
                  await onDeleteDeptByIds(col.id);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">运输单管理</div>
      <QueryTable.Provider useQuery={false} name={TransportTableName} requestFn={getTestPapers}>
        <QueryTable.Filter schema={searchSchema} components={{ Input, DatePicker }} ref={formRef} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="paperId"
          scroll={{ x: 800 }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新建运输单
            </Button>
          }
          extraRightTool={
            <Button icon={<AiOutlineDownload />} onClick={onDownload}>
              下载运输单
            </Button>
          }
        />
      </QueryTable.Provider>
      <TransportModal
        data={currentData}
        open={visible}
        onCancel={() => {
          setFalse();
          setCurrentData(null);
        }}
        onRefresh={onRefresh}
      />
    </Card>
  );
}
